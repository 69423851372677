<template>
  <div>
    <b-modal
      id="modalReasonEdit"
      title="Изменить причину"
      size="md"
      hide-footer
      no-enforce-focus
      :no-close-on-backdrop="true"
      @show="getCancels()"
      @close="closeModal()"
    >
      <b-row>
        <b-col>
          <b-form-group>
            <label>Наименование<i style="color: red">*</i></label>
            <b-form-input
              v-model="editreason.name"
              id="basicInput"
              placeholder="Наименование"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <label>Выберите тип<i style="color: red">*</i></label>
          <v-select
            label="name"
            v-model="editreason.reason_order_type"
            :options="reasonTypes"
            :reduce="options => options.id"
            placeholder="Тип"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <label>Добавить статус</label>
          <v-select
            v-model="editreason.order_statuses"
            :reduce="order_status => order_status.id"
            label="name"
            multiple
            :options="order_status"
            class="select-size-md"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col
          style="margin-left: 15px; margin-top: 30px"
          class="custom-control custom-checkbox"
        >
          <input
            type="checkbox"
            v-model="editreason.is_active"
            class="custom-control-input"
            id="customCheck1"
          />
          <label class="custom-control-label" for="customCheck1">Статус</label>
        </b-col>
      </b-row>
      <b-row class="d-flex justify-content-around">
        <b-button class="mt-2 col-md-5" @click="editCancel()" variant="primary">
          Добавить
        </b-button>
        <b-button class="mt-2 col-md-5" @click="closeModal()"> Отмена </b-button>
      </b-row>
    </b-modal>
  </div>
</template>
<script>
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
export default {
  props: ["id",'reasonTypes'],
  components: {
    vSelect,
    ToastificationContent,
  },
  data() {
    return {
      editreason: {
        name: '',
        order_statuses: [],
        reason_order_type: '',
        is_active: null
      },
      order_status: [],
    };
  },
  methods: {
    closeModal() {
      this.editreason = {
        name: '',
        order_statuses: [],
        reason_order_type: '',
        is_active: null
      }
      this.order_status = [],
      this.$bvModal.hide("modalReasonEdit");
    },
    getCancels() {
      this.$http
        .get(`settings-order/cancel-reason/${this.id.id}/edit`)
        .then((res) => {
          this.editreason.name = res.data.name
          this.editreason.order_statuses = res.data.order_statuses
          this.editreason.reason_order_type = res.data.reason_order_type
          this.editreason.is_active = res.data.is_active == 1 ? true : false
        })
        .catch((err) => {
          console.log(err);
        });
      this.$http
        .get("settings-order/order-status")
        .then((res) => {
          this.order_status = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    editCancel() {
      this.editreason.is_active = this.editreason.is_active == true ? 1 : 0;
      this.$http
        .patch(`settings-order/cancel-reason/${this.id.id}`, this.editreason)
        .then((res) => {
          this.closeModal()
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Успех!",
              icon: "CheckIcon",
              variant: "success",
              text: `Тип улицы успешно изменена!`,
            },
          });
          this.$emit('refresh')
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Некорректные данные!",
              icon: "XIcon",
              variant: "danger",
              text: err.response.data.errors,
            },
          });
        });
    },
  },
};
</script>
