<template>
        <div>
            <b-row>
                <b-col cols="12">
                    <b-card class="my-card m-0">
                        <b-table 
                            striped
                            small
                            id="myTables"
                            bordered
                            selectable
                            selected-variant="primary"
                            select-mode="single"
                            responsive="sm"
                            @row-dblclicked="editForm"
                            @row-clicked="clicked"
                            :items="orderReason"
                            :fields="fields"
                            head-row-variant="secondary"
                            >
                            <template #cell(is_active)="data">
                                {{ data.item.is_active == 1 ? 'Активен' : 'Неактивен' }}
                            </template>
                        </b-table>
                    </b-card>
                </b-col>
            </b-row>
        </div>
    </template>
        <script>
        import { resizeable } from "@/utils/TableFunctions/resizeable.js";
        import { cellSelect } from "@/utils/TableFunctions/cellSelect";
        export default {
            props: ['orderReason','id'],
            data(){
                return {
                    fields: [ 
                        { key: 'checkbox', label: '', thStyle: {width: '10px'}},
                        { key: 'id', label: 'Id', sortable: true, thStyle: {width: '150px'}},
                        { key: 'name', label: 'Наименование', sortable: true, thStyle: {width: '150px'}},
                        { key: 'is_active', label: 'Статус', sortable: true, thStyle: {width: '150px'}},
                        { key: 'reason_order_type', label: 'Тип', sortable: true, thStyle: {width: '150px'}},
                    ],
                }
            },
            mounted(){
                resizeable()
            },
            methods:{
                editForm(id){
                    this.$emit('editForm', id)
                },
                clicked(item, index, event){
                    this.$store.state.filterPlus.tableIndex = index
                    const clickedElement = event.target;
                    const clickedCell = clickedElement.closest('td');
                    if (!clickedCell) {
                        return;
                    }
                    const columnIndex = Array.from(clickedCell.parentNode.children).indexOf(clickedCell);
                    if (columnIndex < 0 || columnIndex >= this.fields.length) {
                        return;
                    }
                    const clickedField = this.fields[columnIndex];
                    this.$store.state.filterPlus.keyFilter = clickedField.key;
                    this.$store.state.filterPlus.filterPlus = (event.target.innerHTML).trim()
                    cellSelect()
                },
            }
        }
        </script>
    
        <style lang="scss">
    
        </style> 