<template>
    <div>
        <div class="d-flex justify-content-center align-items-center" v-if="showPreloader || $store.state.refresh.fetchingNewData || $store.state.refresh.waiting_filter" style="height: 90vh">
            <b-spinner variant="primary" label="Text Centered" />
        </div>
        <div v-else>
            <table-order-reason @editForm="editForm" @refresh="refresh" :orderReason="orderReason"></table-order-reason>
        </div>
        <modal-order-reason @refresh="refresh" :reasonTypes="reasonTypes" :id="id"></modal-order-reason>
        <modal-reason-edit  @refresh="refresh" :reasonTypes="reasonTypes" :id="id"></modal-reason-edit>
        <filter-modalVue :fields="fields" @sendToParent="sendToParent"></filter-modalVue>
    </div>
</template>

<script>
import modalOrderReason from "@/views/component/Modal/ModalOrderReason/modalOrderReason.vue";
import tableOrderReason from "@/views/component/Table/tableOrderReason.vue";
import modalReasonEdit from '@/views/component/Modal/ModalOrderReason/modalReasonEdit.vue';
export default {
  components: {
    tableOrderReason,
    modalOrderReason,
    modalReasonEdit
  },
  data(){
        return {
            id:'',
            orderReason:[],
            showPreloader: false,
            reasonTypes: [
                {
                id: 'PERFORMER',
                name: 'PERFORMER'
                },
                {
                id: 'CLIENT',
                name: 'CLIENT'
                },
                {
                id: 'USER',
                name: 'USER'
                },
            ],
            fields: [ 
                { key: 'name', label: 'Улица'},
                { key: 'is_active', label: 'Статус'},
            ],    
        }
    },
    mounted(){
        this.$store.commit('pageData/setdataCount', null)
        this.openFilter()
        this.refresh()
    },
    methods: {
        editForm(id){
            this.id = id
            setTimeout(this.openModal, 0);
        },
        openModal(){
            this.$bvModal.show('modalReasonEdit')
        },
        openFilter(){
            let allRoutes = this.$store.state.draggableTab.tabs
            let param = JSON.parse(localStorage.getItem(this.$route.name + 'data'))
            let arrayRoutes = []
            allRoutes.forEach(element => {
                arrayRoutes.push(element.path)
            });
            if (arrayRoutes.includes(this.$route.path) == true) {
                this.showPreloader = true
                this.$http
                    .get(`${this.$route.name}`, {params: param}).then(res => {
                        this.orderReason = res.data
                        this.$store.commit('pageData/setdataCount', this.orderReason.length)
                        this.showPreloader = false
                        this.$store.commit('REFRESH_DATA', false)
                    })
            }else if(arrayRoutes.includes(this.$route.path) == false){
                this.$bvModal.show(this.$route.name + 'filter')
            }
        },
        refresh(){
            this.showPreloader = true
            this.$http
                .get('settings-order/cancel-reason').then(res => {
                    this.orderReason = res.data
                    this.$store.commit('pageData/setdataCount', this.orderReason.length)
                    this.showPreloader = false
                    this.$store.commit('REFRESH_DATA', false)
                })
        },
        sendToParent(tableData){
            this.orderReason = tableData
            this.$store.commit('pageData/setdataCount', this.orderReason.length)
        },
    },
    computed: {
        fetchingNewData(){
            return this.$store.state.refresh.fetchingNewData
        }
    },
    watch: {
        fetchingNewData(val){
            if (val){
                this.$http
                .get(`${this.$route.name}`)
                .then(res =>{
                    this.orderReason = res.data
                    this.$store.commit('pageData/setdataCount', this.orderReason.length)
                    this.$store.commit('REFRESH_DATA', false)
            })
        }
      }
    }
};
</script>
